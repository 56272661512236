export const calculationDatapoints = {
  order: 1,
  name: "Perform calculations on datapoints",
  subcategory: "Analyze datapoints",
  markdown: `# Perform calculations on datapoints
  Certain datapoints produce output values via calculations performed on collections of other datapoints. These so-called *calculation datapoints* orchestrate revenue sharing, pay-as-you-go pricing, and public/private access to help Tower Hunt deliver value to the data's consumers **and** contributors. Read on to learn how to use calculations.

  ## What to expect

  Calculation datapoints bring together multiple individual datapoints and then access the information from each datapoint to perform a calculation. If any of the involved datapoints require payment and are not already licensed by you, you are presented with an {% inlineRouterLink articleId="unlock-datapoints" %}unlocking slider and cost summary{% /inlineRouterLink %}.

  ### Manual calculations

  Sometimes Tower Hunt requires you to trigger a calculation manually by {% inlineAppIcon iconName="cursor" /%}left clicking on the calculation datapoint. This happens in situations where large volumes of data are present and you are able to adjust which datapoints you want to include or exclude, such as in the {% inlineRouterLink articleId="the-role-of-the-analyze-tab" %}Analyze tab{% /inlineRouterLink %}. You'll know you are facing a manual calculation because the datapoint will say **Get**.

  ![Manual calculation screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="caution" marginStyles="mb-4" %}
  **Caution:** Depending upon your criteria, manual calculations may result in longer load times and larger {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}datapoint costs{% /inlineRouterLink %} for you.
  {% /callout %}

  ### Automatic calculations

  When a calculation is being performed on a specific record (ex. price per area for a valuation), it will attempt to run automatically. If any of the involved datapoints require payment and are not already licensed by you, you are presented with an {% inlineRouterLink articleId="unlock-datapoints" %}unlocking slider and cost summary{% /inlineRouterLink %}.

  ![Automatic calculation screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Appearance

  Calculation datapoints are identifiable by the calculator icon and teal coloring. Here's an example: {% inlineDatapoint state="calculation" /%}

  ## Interactions

  Like other datapoints, you interact with calculation datapoints by {% inlineAppIcon iconName="cursor" /%}left clicking on them to expose the action menu.

  ### Unlock datapoints

  Calculation datapoints always provide a {% inlineRouterLink articleId="unlock-datapoints" %}cost summary{% /inlineRouterLink %} and contributor list for the included datapoints. If any of the involved datapoints require payment and are not already licensed by you, you are also presented with an {% inlineRouterLink articleId="unlock-datapoints" %}unlocking slider{% /inlineRouterLink %}.

  ![Calculation unlock screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Calculation options

  Most calculations offer options that influence which datapoints are used and/or which variation of a particular calculation is performed. These options are not persisted across the user's session, so be sure to review them as you're getting used to Tower Hunt so that you understand the default settings.

  ![Calculation options screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
};
